import { getUmpHost } from '@shared/lib'
// пока только для ssg, чтоб лишнее не тащить
export const getCommonRequest = async <T>(
  mainSection: string,
  init?: RequestInit & { next: { [key: string]: string | number } },
  errorMessage = 'CONSUL REQUEST ERROR'
): Promise<{ data: T; status: number; error: Error }> => {
  try {
    const response = await fetch(`${getUmpHost()}/portal-content/public/content/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify({
        page: mainSection,
      }),
      ...(init && init),
    })

    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`)
    }

    const data = (await response.json()) as T

    return { data, status: response.status, error: null }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(errorMessage, error instanceof Error ? error.message : '')

    return { error, data: null, status: error.status }
  }
}
