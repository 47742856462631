import React, { FC, useEffect, useState } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { Logo } from '@shared/ui'
import { getAssetsPath } from '@shared/lib'
import { TNavigation } from '../types'
import { Styled } from '../styled'
import { Modal } from './Modal/ui'
import { linkProps } from '../config'

export const Navigation: FC<TNavigation> = ({ links, active = null, login, logoLink }) => {
  const [isOpenModal, setIsOpenModal] = useState(false)

  useEffect(() => {
    setIsOpenModal(false)
  }, [active])

  return (
    <>
      <Modal isOpen={isOpenModal} onClose={() => setIsOpenModal(false)} links={links} login={login} active={active} />
      <Styled.Container>
        <Logo link={logoLink}>
          <Styled.MobileContainer onClick={() => setIsOpenModal(true)}>
            <Image priority src={getAssetsPath('icons/more.svg')} alt="лого" width={22} height={18} />
          </Styled.MobileContainer>
          <Styled.TabletContainer>
            {Boolean(login) && (
              <Link href={login.href}>
                <Styled.Text {...linkProps}>{login.title}</Styled.Text>
              </Link>
            )}
          </Styled.TabletContainer>
        </Logo>
        <Styled.LinkGrid>
          {links.map(({ href, title, alias }) => (
            <Link href={href} key={href}>
              <Styled.Text isActive={active === alias} {...linkProps}>
                {title}
              </Styled.Text>
              <Styled.Underline isActive={alias === active} />
            </Link>
          ))}
          {Boolean(login) && (
            <Link href={login.href}>
              <Styled.TextLogin {...linkProps}>{login.title}</Styled.TextLogin>
              <Styled.Underline />
            </Link>
          )}
        </Styled.LinkGrid>
      </Styled.Container>
    </>
  )
}
