import styled from 'styled-components'
import { theme } from '@shared/config'

const Container = styled.a`
  text-decoration: none;
  display: flex;
  padding: ${theme.spacings.xs3}px;
  background: ${theme.colors.neutral};
  border-radius: ${theme.radiuses.xs}px;
  border: ${theme.borders.basic};
  justify-content: space-between;
  width: 100%;
  align-items: center;

  > :not(:last-child) {
    margin-right: ${theme.spacings.xs1}px;
  }

  @media (min-width: ${theme.breakpoints.sm}px) {
    > :not(:last-child) {
      margin-right: ${theme.spacings.md6}px;
    }
  }
`

export const Styled = {
  Container,
}
