import React, { FC, ReactNode } from 'react'
import { Footer, Navigation } from '@entities'
import { Layout } from '@shared/ui'
import { TPageLayout } from './types'

type Props = TPageLayout & {
  children: ReactNode | ReactNode[]
  active?: string
}

export const PageLayout: FC<Props> = ({ children, footer, active, navigation }) => (
  <Layout>
    <div>
      <Navigation {...navigation} active={active} />
      {children}
    </div>
    <Footer {...footer} />
  </Layout>
)
