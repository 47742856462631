import React, { FC, useEffect, useMemo } from 'react'
import Link from 'next/link'
import { theme } from '@shared/config'
import { Logo } from '@shared/ui'
import { getAssetsPath } from '@shared/lib'
import { Styled } from './styled'
import { TModal } from './types'

export const Modal: FC<TModal> = ({ isOpen, onClose, active, links, login }) => {
  const data = useMemo(() => (login ? links.concat([login]) : links), [links, login])

  useEffect(() => {
    document.body.style.overflow = isOpen && 'hidden'

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [isOpen])

  if (!isOpen) {
    return null
  }

  return (
    <Styled.Container>
      <Logo>
        <Styled.Image
          priority
          src={getAssetsPath('icons/close.svg')}
          alt="закрыть"
          width={22}
          height={18}
          onClick={onClose}
        />
      </Logo>
      <Styled.Body>
        {data.map(({ href, title, alias }) => (
          <Link href={href} key={href}>
            <Styled.Text
              fontSizeMob={theme.fonts.fontSizes.sm1}
              fontWeightMob={theme.fonts.fontWeights.bold600}
              isActive={alias === active}
            >
              {title}
            </Styled.Text>
          </Link>
        ))}
      </Styled.Body>
    </Styled.Container>
  )
}
