import { colors } from './colors'
import { spacings } from './spacings'
import { fonts } from './fonts'

const breakpoints = {
  none: 0,
  mob: 320,
  mobSm: 414,
  tablet: 744,
  sm: 1024,
  md: 1280,
  lg: 1440,
} as const

const borderSizes = {
  basic: 1,
} as const

const theme = {
  colors,
  fonts,
  breakpoints,
  spacings,
  transitions: {
    basic: '200ms ease',
    show: 'transform 500ms ease-in-out',
  },
  borderSizes,
  borders: {
    basic: `${borderSizes.basic}px solid ${colors.grayLight}`,
  },
  radiuses: {
    xs: 8,
  },
  header: {
    fontWeightMob: fonts.fontWeights.bold,
    fontSizeMob: fonts.fontSizes.md1,
    fontSizeSm: fonts.fontSizes.xl,

    marginBottomMob: spacings.xs3,
    marginBottomTablet: spacings.sm2,
    marginBottomMd: spacings.md4,
    marginBottomLg: spacings.lg2,
  },
  paragraph: {
    fontSizeMob: fonts.fontSizes.xs1,
    fontSizeSm: fonts.fontSizes.sm,
  },
  copyright: {
    fontSizeMob: fonts.fontSizes.xs,
    lineHeight: fonts.lineHeights.default,
    color: colors.grayLight,
    fontWeightMob: fonts.fontWeights.regular300,
  },
  documentSize: {
    md: 380,
  },
} as const

export { theme }
