import { ReactNode } from 'react'
import { DSLParser } from './DSLParser'

const templates = {
  '{nbsp}': '\u00A0',
  '{shy}': '\u00AD',
  '{hyphen}': '\u2010',
}

const getString = (string: string) =>
  string.replace(new RegExp(Object.keys(templates).join('|'), 'g'), (key) => templates[key])

export const getPreparedChildren = (children: ReactNode) =>
  typeof children === 'string' ? DSLParser(getString(children)) : children
