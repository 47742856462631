import React, { FC } from 'react'
import Image from 'next/image'
import { theme } from '@shared/config'
import { getAssetsPath } from '@shared/lib'
import { Text } from '../Text'
import { Styled } from './styled'
import { TDocument } from './types'

export const Document: FC<TDocument> = ({ name, size, type, url }) => (
  <Styled.Container href={url} target="_blank" rel="noopener noreferrer">
    <div>
      <Text
        fontSizeMob={theme.fonts.fontSizes.sm1}
        color={theme.colors.blueDark}
        marginBottomMob={theme.spacings.xs}
        fontWeightMob={theme.fonts.fontWeights.medium}
      >
        {name}
      </Text>
      <Text
        fontSizeMob={theme.fonts.fontSizes.xs}
        color={theme.colors.grayLight}
        fontWeightMob={theme.fonts.fontWeights.medium}
      >
        {type.toUpperCase()}, {size}
      </Text>
    </div>
    <div>
      <Image priority src={getAssetsPath('icons/document.svg')} alt="документ" height={18} width={14} />
    </div>
  </Styled.Container>
)
