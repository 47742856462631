import React, { FC } from 'react'
import { Text } from '@shared/ui'
import { theme } from '@shared/config'
import { TMoreButton } from './types'
import { Styled } from './styled'

export const MoreButton: FC<TMoreButton> = ({ children, onClick }) => (
  <Styled.Container onClick={onClick}>
    <Text
      fontSizeMob={theme.fonts.fontSizes.sm1}
      fontWeightMob={theme.fonts.fontWeights.bold}
      color={theme.colors.tealDark}
    >
      {children}
    </Text>
  </Styled.Container>
)
