import styled, { css } from 'styled-components'
import ImageImport from 'next/image'
import { theme } from '@shared/config'
import { Text as TextImport } from '@shared/ui'

const BORDER_WIDTH = theme.spacings.xxs

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${theme.spacings.lg3}px;

  > :not(:last-child) {
    margin-bottom: ${theme.spacings.md2}px;
  }
`

const Text = styled(TextImport)<{ isActive?: boolean }>`
  color: ${({ isActive }) => (isActive ? theme.colors.tealDark : theme.colors.blueDark)};
  padding-left: ${theme.spacings.xs4}px;

  ${({ isActive }) =>
    isActive &&
    css`
      border-left: ${BORDER_WIDTH}px solid ${theme.colors.tealDark};
      padding-left: ${theme.spacings.xs4 - BORDER_WIDTH}px;
    `}
`

const Container = styled.div`
  position: absolute;
  z-index: 1;
  padding: ${theme.spacings.sm}px ${theme.spacings.xs3}px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: ${theme.colors.neutral};
`

const Image = styled(ImageImport)`
  cursor: pointer;
`

export const Styled = {
  Body,
  Text,
  Container,
  Image,
}
