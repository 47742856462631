export const fonts = {
  fontSizes: {
    xs: 12,
    xs1: 13,
    xs2: 14,
    sm: 15,
    sm1: 16,
    sm2: 20,
    sm3: 22,
    md: 28,
    md1: 42,
    xl: 64,
    default: 15,
  },
  fontFamily: '"Montserrat", sans-serif',
  fontWeights: { regular300: 300, regular: 400, medium: 500, bold600: 600, bold: 700, black: 900 },
  lineHeights: {
    xs: 20,
    default: 21,
  },
} as const
