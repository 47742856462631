import styled from 'styled-components'
import { theme } from '@shared/config'
import { Text as TextImport } from '@shared/ui'

const Container = styled.div`
  display: flex;
  margin-bottom: ${theme.spacings.lg3}px;
  column-gap: ${theme.spacings.xl3}px;
  flex-direction: column;
  row-gap: ${theme.spacings.md3}px;

  @media (min-width: ${theme.breakpoints.md}px) {
    flex-direction: row;
    margin-bottom: ${theme.spacings.xl4}px;
    justify-content: space-between;
  }
`

const LinkGrid = styled.div`
  display: none;
  align-items: end;

  @media (min-width: ${theme.breakpoints.tablet}px) {
    display: flex;
    column-gap: ${theme.spacings.xs3}px;
  }

  @media (min-width: ${theme.breakpoints.md}px) {
    column-gap: ${theme.spacings.md2}px;
  }
`

const Text = styled(TextImport)<{ isActive?: boolean }>`
  color: ${({ isActive }) => (isActive ? theme.colors.tealDark : theme.colors.blueDark)};
  margin-bottom: ${theme.spacings.xxs}px;
`

const TextLogin = styled(Text)`
  display: none;

  @media (min-width: ${theme.breakpoints.md}px) {
    display: block;
  }
`

const MobileContainer = styled.div`
  display: block;
  cursor: pointer;

  @media (min-width: ${theme.breakpoints.tablet}px) {
    display: none;
  }
`

const TabletContainer = styled.div`
  display: none;

  @media (min-width: ${theme.breakpoints.tablet}px) {
    display: block;
  }

  @media (min-width: ${theme.breakpoints.md}px) {
    display: none;
  }
`

const Underline = styled.div<{ isActive?: boolean }>`
  height: 4px;
  width: 100%;
  background-color: ${theme.colors.tealDark};
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
`

export const Styled = {
  Container,
  LinkGrid,
  Text,
  TabletContainer,
  MobileContainer,
  TextLogin,
  Underline,
}
