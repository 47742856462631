import { Link } from '@shared/ui/Link'

export const DSLRules = [
  {
    token: 'link',
    component: Link,
    content: true,
    defaultProps: {
      target: '_self',
    },
    props: [
      {
        name: 'href',
        required: true,
      },
      {
        name: 'target',
        content: ['_blank', '_self', '_parent', '_top'],
      },
      {
        name: 'colorType',
        content: ['default', 'primary'],
      },
      {
        name: 'underline',
      },
    ],
  },
  {
    token: 'br',
    component: 'br',
  },
  {
    token: 'medium',
    component: 'b',
    content: true,
  },
  {
    token: 'bold',
    component: 'strong',
    content: true,
  },
  {
    token: 'sup',
    component: 'sup',
    content: true,
  },
  {
    token: 'sub',
    component: 'sub',
    content: true,
  },
]
