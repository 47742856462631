import React, { FC } from 'react'
import { Styled } from './styled'
import { TLinkProps } from './types'

const Link: FC<TLinkProps> = ({ colorType = 'default', activeColor, linkRef, size, sizemob, children, ...rest }) => (
  <Styled.LinkWrapper ref={linkRef} colorType={colorType} activeColor={activeColor} {...rest}>
    {children}
  </Styled.LinkWrapper>
)

export { Link }
