import { css } from 'styled-components'
import { getAssetsPath } from '@shared/lib'

const BASE_FONT_URL = '/fonts'

const FONTS = Object.freeze([
  {
    name: 'Montserrat-Light',
    fontWeight: 300,
  },
  {
    name: 'Montserrat-Regular',
    fontWeight: 400,
  },
  {
    name: 'Montserrat-Medium',
    fontWeight: 500,
  },
  {
    name: 'Montserrat-SemiBold',
    fontWeight: 600,
  },
  {
    name: 'Montserrat-Bold',
    fontWeight: 700,
  },
  {
    name: 'Montserrat-Black',
    fontWeight: 900,
  },
])

export const Fonts = css`
  ${FONTS.map(
    ({ name, fontWeight }) => css`
      @font-face {
        font-family: 'Montserrat';
        src:
          url('${getAssetsPath(`${BASE_FONT_URL}/${name}/${name}.woff2`)}') format('woff2'),
          url('${getAssetsPath(`${BASE_FONT_URL}/${name}/${name}.woff`)}') format('woff');
        font-weight: ${fontWeight};
        font-style: normal;
        font-display: swap;
      }
    `
  )}
`
