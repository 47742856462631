import { createGlobalStyle, styled, css } from 'styled-components'
import { theme } from '@shared/config'
import { Fonts } from './lib'
import { normalize } from './config'

export const styleSettings = {
  layout: {
    padding: 17,
    width: {
      tablet: 880,
      sm: 1020,
      md: 1180,
      lg: 1180,
    },
  },
} as const

export const GlobalStyles = createGlobalStyle`
  ${Fonts}
  ${normalize}
  
  body {
    background-color: ${theme.colors.neutral};
    font-family: 'Montserrat', sans-serif;
  }

  * {
    -webkit-tap-highlight-color: transparent;
  }
`

const ContentContainer = styled.div`
  width: 100%;
  max-width: 100%;
  padding: ${theme.spacings.sm}px ${styleSettings.layout.padding}px;
  margin: 0 auto;
  min-height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${Object.entries(styleSettings.layout.width).map(
    ([breakpoint, width]) => css`
      @media (min-width: ${theme.breakpoints[breakpoint]}px) {
        max-width: ${width}px;
      }
    `
  )}

  @media (min-width: ${theme.breakpoints.tablet}px) {
    padding: ${theme.spacings.md3}px ${theme.spacings.xl}px;
  }

  @media (min-width: ${theme.breakpoints.md}px) {
    padding-left: 0;
    padding-right: 0;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  min-width: ${320 - theme.spacings.xs3 * 2}px;
  background-color: ${theme.colors.neutral};
  position: relative;
`

export const Styled = {
  Container,
  ContentContainer,
}
