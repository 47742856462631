import React, { FC, ReactNode } from 'react'
import Image from 'next/image'
import { getAssetsPath } from '@shared/lib'
import { Styled } from './styled'

type Props = {
  children: ReactNode
  link?: string
}

export const Logo: FC<Props> = ({ children, link = '/' }) => (
  <Styled.Container>
    <Styled.LogoContainer href={link}>
      <Image priority fill src={getAssetsPath('/icons/logo.svg')} alt="лого" />
    </Styled.LogoContainer>
    {children}
  </Styled.Container>
)
