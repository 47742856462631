import styled from 'styled-components'
import { theme } from '@shared/config'

const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${theme.colors.iceberg};
  border-radius: ${theme.radiuses.xs}px;
  padding: ${theme.spacings.xs3}px 0;
  cursor: pointer;
`

export const Styled = {
  Container,
}
