import React, { ReactNode } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { GlobalStyles, Styled } from './styled'

export const Layout = ({ children }: { children: ReactNode | ReactNode[] }) => (
  <>
    <GlobalStyles />
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <Styled.Container>
        <Styled.ContentContainer>{children}</Styled.ContentContainer>
      </Styled.Container>
    </ErrorBoundary>
  </>
)
