import { TGenerateBreakpointsStyles } from './types'

export function generateBreakpointStyles({
  breakpoint,
  fontSize,
  lineHeight,
  fontWeight,
  marginBottom,
  marginTop,
}: TGenerateBreakpointsStyles): string {
  return `
        @media (min-width: ${breakpoint}px) {
          ${typeof fontSize === 'number' ? `font-size: ${fontSize}px` : ''};
          ${
            typeof lineHeight === 'number' || typeof fontSize === 'number'
              ? `line-height: ${lineHeight || fontSize}px`
              : ''
          };
          ${typeof fontWeight === 'number' ? `font-weight: ${fontWeight}` : ''};
          ${typeof marginBottom === 'number' ? `margin-bottom: ${marginBottom}px` : ''};
          ${typeof marginTop === 'number' ? `margin-top: ${marginTop}px` : ''};
        }
      `
}
