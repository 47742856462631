import styled from 'styled-components'
import { theme } from '@shared/config'
import { TLinkProps } from './types'

const colorTypeMap = {
  currentColor: 'currentColor',
  default: theme.colors.blueDark,
  primary: theme.colors.tealDark,
}

const LinkWrapper = styled.a<Pick<TLinkProps, 'colorType' | 'activeColor' | 'underline'>>`
  width: auto;
  display: 'inline-flex';
  cursor: pointer;
  color: ${({ colorType }) => colorTypeMap[colorType]};
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  transition: unset;

  &:hover {
    text-decoration: underline;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &:focus {
    outline: auto;
  }

  &:focus-visible {
    outline: auto;
  }

  &:active {
    color: ${({ activeColor = theme.colors.blueDark }) => activeColor};
  }
`

const Styled = {
  LinkWrapper,
}

export { Styled }
