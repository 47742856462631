import React, { FC, ReactNode } from 'react'
import { getPreparedChildren } from '@shared/ui/DSL'
import { TTextStyledProps } from './types'
import { Styled } from './styled'

type Props = TTextStyledProps & {
  children: ReactNode
}

export const Text: FC<Props> = ({ children, ...props }) => (
  <Styled.Container {...props}>{getPreparedChildren(children)}</Styled.Container>
)
