import React, { FC } from 'react'
import Link from 'next/link'
import { Text } from '@shared/ui'
import { theme } from '@shared/config'
import { TFooter } from './types'
import { Styled } from './styled'

export const Footer: FC<TFooter> = ({ phone, description, links, copyright }) => (
  <Styled.Container>
    <Styled.BodyContainer>
      <Text fontSizeMob={theme.fonts.fontSizes.sm3} fontWeightMob={theme.fonts.fontWeights.bold}>
        {phone}
      </Text>
      {Boolean(links?.length) && (
        <Styled.LinkGrid>
          {links.map(({ href, title }) => (
            <Link href={href} key={href}>
              <Text fontSizeMob={theme.fonts.fontSizes.xs2} color={theme.colors.blueDark}>
                {title}
              </Text>
            </Link>
          ))}
        </Styled.LinkGrid>
      )}
      <div>
        <Text {...theme.copyright} marginBottomMob={theme.spacings.xs}>
          {copyright} {new Date().getFullYear()}
        </Text>
        <Text {...theme.copyright}>{description}</Text>
      </div>
    </Styled.BodyContainer>
  </Styled.Container>
)
