import React, { FC, PropsWithoutRef } from 'react'
import { DSLRules } from './DSLRules'

const DSLGlobalRegExp = new RegExp(
  DSLRules.map(({ token, content }) => (content ? `(?={${token}.+?{/${token}})` : `(?={${token}})`)).join('|'),
  'gm'
)

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const checkProps = <T extends unknown>(
  propsObject: PropsWithoutRef<T>,
  propsRules: Array<{ name: string; required?: boolean; content?: Array<Element> }>
) => {
  propsRules.forEach((rule) => {
    const { name, required, content } = rule
    const propValue = propsObject[name]

    if (required && !propValue) {
      throw new Error(`Required prop "${name}" is missing`)
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (content && propValue && !content.includes(propValue)) {
      throw new Error(`Prop "${name}" value doesn't match allowed content: ${content}`)
    }
  })
}

const getProps = (
  str: string,
  token: string,
  propsRules: Array<{ name: string; required?: boolean; content?: Array<Element> }>
) => {
  const propsArray = str
    .split(new RegExp(`{${token}(.+?)}`))
    .filter((item) => !!item)[0]
    .trim()
    .split(/(.+?)="(.*?)"/)
    .filter((item) => !!item)

  if (propsArray.length % 2) throw new Error('Empty props is not allowed') // empty prop value

  const props = propsArray
    .reduce((acc, curr, i) => {
      if (!(i % 2)) acc.push(propsArray.slice(i, i + 2))

      return acc
    }, [])
    .reduce((obj, item) => Object.assign(obj, { [item[0].trim()]: item[1] }), {})

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  checkProps(props, propsRules) // check for correct props

  return props
}

const createComponent = (
  str: string,
  rule: {
    token: string
    props?: Array<{ name: string; required?: boolean }>
    component: FC | string
    content?: boolean
    defaultProps?: {
      target?: string
      as?: string
    }
  }
) => {
  try {
    const { token, props, component, defaultProps, content } = rule
    const splitRegexp = new RegExp(
      content ? `({/${token}}|{${token}${props ? '.*?' : ''}})` : `({${token}${props ? '.*?' : ''}})`,
      'gm'
    )
    const componentsParts = str.split(splitRegexp).filter((item) => !!item)
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    const [unparsedProps, enclosedText, _, append] = componentsParts
    const appendText = content ? append : enclosedText
    const componentProps = rule.props ? getProps(unparsedProps, token, props) : []
    const Component = component

    return [
      content ? (
        <Component {...defaultProps} {...componentProps}>
          {enclosedText}
        </Component>
      ) : (
        <Component {...defaultProps} {...componentProps} />
      ),
      appendText,
    ]
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e.message)

    return str
  }
}

export const DSLParser = (stringToParse: string) => {
  const parsed = stringToParse.split(DSLGlobalRegExp).map((str) => {
    const rule = DSLRules.find((item) => {
      const regExpToken = new RegExp(`^{${item.token}`, 'gm')

      return regExpToken.test(str)
    })

    return rule ? createComponent(str, rule) : str
  })

  return Array.isArray(parsed) ? React.Children.toArray(parsed) : stringToParse
}
