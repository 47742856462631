import styled from 'styled-components'
import Link from 'next/link'
import { theme } from '@shared/config'

const LogoContainer = styled(Link)`
  position: relative;
  width: 160px;
  height: 20px;

  @media (min-width: ${theme.breakpoints.tablet}px) {
    width: 180px;
    height: 23px;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`

export const Styled = {
  LogoContainer,
  Container,
}
